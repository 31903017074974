import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div
          style={{ backgroundColor: "#333" }}
          className="container-fluid border-bottom-sm-grey"
        >
          <div className="mx-5 pt-5">
            <div className="row">
              <div className="col-sm-12 col-md-6 d-flex justify-content-center">
                <div className="col-sm-6 py-4 border-right-md">
                  <div>
                    <img
                      src={require("../assets/img/logo.svg")}
                      alt="Kelolabiz"
                      className="img img-auto img-footer mb-4"
                    />
                  </div>
                  <div>
                    <p className="sans text-white text-footer">
                      Kelola.Biz adalah layanan dalam bidang informasi teknologi
                      yang siap membantu perusahaan atau industri manufaktur
                      untuk meningkatkan efektivitas proses kerja melalui
                      otomatisasi dan digitalisasi.
                    </p>
                    <div className="sans text-white text-footer">
                      Tim Profesional IT kami siap membantu anda menghadapi
                      tantangan era revolusi Industri 4.0. dengan membuat proses
                      kerja lebih efektif, mudah, & dapat diakses kapanpun dan
                      dimanapun. Dampak dari tujuan tersebut adalah efisiensi
                      Waktu dan Biaya sehingga dapat meningkatkan Profit
                      Perusahaan anda.
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 py-4 pl-5">
                  <h5 className="mont mb-4 text-white title-footer font-weight-bold">
                    Recent News
                  </h5>
                  <ul className="list-bottom-kelola">
                    <li className="mb-4">
                      <p className="sans text-white mb-2 post-footer">
                        Before we talk destination, we shine a spotlight across
                        your organization to fully understand its people,
                        processes, and technology.
                      </p>
                      <small className="text-white font-weight-bold sans">
                        21.January.2020
                      </small>
                    </li>
                    <li>
                      <p className="sans text-white mb-2 post-footer">
                        Before we talk destination, we shine a spotlight across
                        your organization to fully understand its people,
                        processes, and technology.
                      </p>
                      <small className="text-white font-weight-bold sans">
                        21.January.2020
                      </small>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 p-0 d-flex justify-content-center border-top-sm-grey">
                {/* <div className="row"> */}
                {/* <div className="col-sm-12 p-0"> */}

                <div className="col-sm-6 col-md-4 py-4 pt-4 mr-3 ml-3 p-0 pl-3 border-right-sm-grey border-right-md">
                  <h5 className="mont mb-3 title-footer text-white font-weight-bold">
                    Menu
                  </h5>
                  <ul className="bottom-menu-kelolabiz">
                    <li className="font-weight-bold sans">
                      <Link to="/solution" className="text-white">
                        Solution
                      </Link>
                    </li>
                    <li className="sans">
                      <Link to="/product" className="text-white">
                        Product
                      </Link>
                    </li>
                    <li className="sans">
                      <Link to="/service" className="text-white">
                        Service
                      </Link>
                    </li>
                    <li className="sans">
                      <Link to="/industry" className="text-white">
                        Industry
                      </Link>
                    </li>
                    <li className="sans">
                      <Link to="/about-us" className="text-white">
                        About Us
                      </Link>
                    </li>
                    <li className="sans">
                      <Link to="/blog" className="text-white">
                        Blog
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-8 pt-4">
                  <div className="d-flex">
                    <div className="col p-0">
                      <h5 className="mont mb-4 text-white title-footer font-weight-bold">
                        Contact Us
                      </h5>
                      <div className="row">
                        <div className="col">
                          <h6 className="sans text-white subtitle-footer font-weight-bold">
                            <i className="mdi mdi-email-outline mr-2 text-yellow"></i>{" "}
                            <a
                              className="text-white"
                              href="mailto:admin@kelola.biz"
                            >
                              admin@kelola.biz
                            </a>
                          </h6>
                        </div>
                        <div className="col">
                          <a
                            style={{ fontSize: ".7em" }}
                            className="py-2 px-3 text-white button-green"
                            href="https://api.whatsapp.com/send?phone=6288908840808&amp;text= Halo"
                            target="_blank"
                          >
                            {" "}
                            <i className="mdi mdi-phone mr-2"></i> +62
                            889-0884-0808
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex mt-4">
                    <div className="col p-0">
                      <h5 className="mont mb-4 text-white title-footer font-weight-bold">
                        Sosial Media
                      </h5>
                      <h6 className="sans subtitle-footer text-white mb-3 font-weight-bold">
                        <span className="mr-2 badge-footer">
                          <i className="mdi mdi-linkedin"></i>
                        </span>{" "}
                        <a
                          className="text-white"
                          href="https://www.linkedin.com/company/kelola-biz/"
                          target="_blank"
                        >
                          kelola.biz
                        </a>
                      </h6>

                      <h6 className="sans subtitle-footer text-white mb-3 font-weight-bold">
                        <span className="mr-2 badge-footer">
                          <i className="mdi mdi-instagram"></i>
                        </span>{" "}
                        <a
                          className="text-white"
                          href="https://www.instagram.com/kelola.biz/"
                          target="_blank"
                        >
                          kelola.biz
                        </a>
                      </h6>
                    </div>
                  </div>

                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#333" }}
          className="container-fluid text-center border-top-md"
        >
          <h6 className="sans footer-copyright py-4 text-white font-weight-bold m-0">
            Copyright © 2019 Kelola.biz . All Rights Reserved.
          </h6>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
