import React, { Component, Fragment } from 'react'

export class ImageFeature extends Component {
    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="image-feature pt-5 px-4">
                        <img src={this.props.image ? this.props.image : 'https://via.placeholder.com/1200/FFF'} alt="RFID" className="img-feature" />
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ImageFeature
