const initState = {
    post: [],
    errors: null,
    fetching: false,
    fetched: false,
}

const postReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_POST_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'FETCH_POST_SUCCESS':
            return {
                ...state,
                post: action.data,
                errors: null,
                fetching: false,
                fetched: true
            }
        case 'FETCH_POST_FAILED':
            return {
                ...state,
                errors: action.errors,
                fetching: false,
                fetched: true
            }
        default :
            return state
    }
}

export default postReducer