import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

export class Navbar extends Component {
  state = {
    menuOpen: false,
    fixed: false,
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    if (window.pageYOffset > 154) {
      this.setState({
        ...this.state,
        fixed: true,
      });
    } else {
      this.setState({
        ...this.state,
        fixed: false,
      });
    }
  };

  render() {
    const styles = {
      navbarUp: {
        paddingTop: "30px",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      badgeWhite: {
        backgroundColor: "#1d5ea8",
        borderRadius: "50%",
        width: "25px",
        height: "25px",
        color: "#fff",
        display: "inline-block",
        textAlign: "center",
      },
      badge: {
        backgroundColor: "#fff",
        borderRadius: "50%",
        width: "25px",
        height: "25px",
        color: "#1d5ea8",
        display: "inline-block",
        textAlign: "center",
      },
      divider: {
        borderLeft: "1px solid #fff",
        marginLeft: "10px",
        marginRight: "10px",
      },
      navbar: {
        padding: this.state.fixed ? "20px 50px" : "0px 20px",
        fontFamily: "'Source Sans Pro', sans-serif",
      },
    };

    return (
      <Fragment>
        <div
          className="container-fluid navbar-kelolabiz-wrap"
          style={
            this.state.menuOpen
              ? { backgroundColor: "#fff", transition: "all .3s ease-in-out" }
              : { transition: "all .3s ease-in-out" }
          }
        >
          <ul className="nav navbar-kelolabiz-small sans mb-4 d-sm-block d-md-none px-2">
            <li className="nav-item">
              <a
                className="nav-link px-auto"
                // href="https://wa.me/6288908840808"
                href ="https://api.whatsapp.com/send?phone=6288908840808&amp;text=Halo"
                target="_blank"
              >
                WhatsApp Only +62 889-0884-0808
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-auto" href="https://www.linkedin.com/company/kelola-biz/" target="_blank">
                {" "}
                <i className="mdi mdi-linkedin mr-1"></i>kelolabiz
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-auto" href="https://www.instagram.com/kelola.biz/" target="_blank">
                {" "}
                <i className="mdi mdi-instagram mr-1"></i>kelolabiz
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-auto" href="#">
                Register
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-auto font-weight-light" href="#">
                Login/SignIn
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link px-2">
                <img
                  src={require("../assets/img/bahasa.svg")}
                  style={{ width: "7px" }}
                  alt="Bahasa Indonesia"
                />
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link px-0">
                <img
                  src={require("../assets/img/english.svg")}
                  style={{ width: "7px" }}
                  alt="English"
                />
              </a>
            </li>
          </ul>
          <nav
            className={`navbar d-none d-md-block navbar-expand-lg px-0 navbar-dark bg-transaparent navbar-kelolabiz mb-4 ${
              this.state.menuOpen && "navbar-kelolabiz-menu-open"
            }`}
            style={styles.navbarUp}
          >
            <ul className="navbar-nav justify-content-end my-lg-0">
              <li className="nav-item">
                <a href="https://www.linkedin.com/company/kelola-biz/" className="nav-link" target="_blank">
                  <span
                    className="mr-2"
                    style={
                      this.state.menuOpen ? styles.badgeWhite : styles.badge
                    }
                  >
                    <i className="mdi mdi-linkedin"></i>
                  </span>
                  kelola.biz
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.instagram.com/kelola.biz/" className="nav-link" target="_blank">
                  <span
                    className="mr-2"
                    style={
                      this.state.menuOpen ? styles.badgeWhite : styles.badge
                    }
                  >
                    <i className="mdi mdi-instagram"></i>
                  </span>
                  kelola.biz
                </a>
              </li>
              {/* <li style={styles.divider} />
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <img src={require('../assets/img/bahasa.svg')} alt="Bahasa Indonesia" />
                                </a>
                            </li> */}
            </ul>
          </nav>
          <nav
            className={`navbar ${
              this.state.fixed ? "fixed-top navbar-kelolabiz-stick" : ""
            } navbar-expand-lg navbar-dark bg-transaparent navbar-kelolabiz ${
              this.state.menuOpen && "navbar-kelolabiz-menu-open"
            }`}
            style={styles.navbar}
          >
            <Link
              onClick={() => this.setState({ ...this.state, menuOpen: false })}
              className="navbar-brand mr-5"
              to="/"
            >
              <img
                src={require(this.state.menuOpen || this.state.fixed
                  ? "../assets/img/logo-dark.svg"
                  : "../assets/img/logo.svg")}
                alt="Kelola.biz"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto navbar-kelolabiz-left">
                <li className="nav-item active dropdown has-megamenu">
                  <a
                    style={
                      this.state.menuOpen ? { backgroundColor: "#ffce00" } : {}
                    }
                    className="nav-link"
                    href="#"
                    onMouseLeave={() =>
                      this.setState({ ...this.state, menuOpen: false })
                    }
                    onMouseEnter={() =>
                      this.setState({ ...this.state, menuOpen: true })
                    }
                  >
                    Solution
                  </a>

                  <div
                    onMouseLeave={() =>
                      this.setState({ ...this.state, menuOpen: false })
                    }
                    onMouseEnter={() =>
                      this.setState({ ...this.state, menuOpen: true })
                    }
                    className="container-fluid pt-5 p-0"
                    style={
                      this.state.menuOpen
                        ? {
                            transition: "all .3s ease-in-out",
                            position: "absolute",
                            width: "100%",
                            height: "auto",
                            backgroundColor: "#fff",
                            left: "0",
                            zIndex: "999",
                          }
                        : { display: "none", transition: "all .3s ease-in-out" }
                    }
                  >
                    <div className="d-flex px-5">
                      <div className="row">
                        <div className="col-sm-12 mb-4 col-md border-right border-warning">
                          <div className="text-center">
                            <span className="menu-icon text-navy">
                              <i className="icon-payment"></i>
                            </span>
                          </div>
                          <h6 className="mb-4 text-muted font-weight-bold text-uppercase sans">
                            purchasing <br /> Warehousing + Inventory
                          </h6>
                          <ul className="mega-menu-list sans">
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/procurement"
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                              >
                                Procurement
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/warehousing-ordering-system"
                              >
                                Inventory Management & Warehousing Ordering
                                System
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/inventory-management"
                              >
                                <div className="d-flex">
                                  <div className="col-8 p-0">
                                    Shipment / Fleet Management [GPS Tracking
                                    System]
                                  </div>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-12 mb-4 col-md border-right border-warning">
                          <div className="text-center">
                            <span className="menu-icon text-lime">
                              <i className="icon-monitor"></i>
                            </span>
                          </div>
                          <h6 className="mb-4 text-muted font-weight-bold text-uppercase sans">
                            production
                          </h6>
                          <ul className="mega-menu-list sans">
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/system-monitoring-production"
                              >
                                SYSTEM MONITORING PRODUCTION [Incl. BOM - Bill
                                Of Material]
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/monitoring-eci"
                              >
                                PROJECT MANAGEMENT [Incl. ECI]
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/standad-project-management"
                              >
                                Quality Monitoring System [QA/QC]-Control Check
                                Alerts
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/maintenance"
                              >
                                Preventive & Predictive Maintenance
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-12 mb-4 col-md border-right border-warning">
                          <div className="text-center">
                            <span className="menu-icon text-navy">
                              <i className="icon-chart"></i>
                            </span>
                          </div>
                          <h6 className="mb-4 text-muted font-weight-bold text-uppercase sans">
                            Marketing
                          </h6>
                          <ul className="mega-menu-list sans">
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/crm"
                              >
                                CRM [Customer Relationship Management]
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/pos"
                              >
                                POS [Point of Sale]- Small-to-Micro Retail
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-12 mb-4 col-md border-right border-warning">
                          <div className="text-center">
                            <span className="menu-icon text-teal">
                              <i className="icon-handcog"></i>
                            </span>
                          </div>
                          <h6 className="mb-4 text-muted font-weight-bold text-uppercase sans">
                            human resources
                          </h6>
                          <ul className="mega-menu-list sans">
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/hris"
                              >
                                HRIS [Human Resources Information System]
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/reqruitment"
                              >
                                Recruitment
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/training-system"
                              >
                                Training System
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/online-expense-management"
                              >
                                Online Expense Management [Reimbursement]
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/online-leaves-management"
                              >
                                Online Leaves Management [Cuti]
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/employee-evaluation"
                              >
                                Employee Evaluation [Appraisal]
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-12 mb-4 col-md">
                          <div className="text-center">
                            <span className="menu-icon text-purple">
                              <i className="icon-todo"></i>
                            </span>
                          </div>
                          <h6 className="mb-4 text-muted font-weight-bold text-uppercase sans">
                            Accounting, Finance &amp; Admin
                          </h6>
                          <ul className="mega-menu-list sans">
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/finance-accounting-software"
                              >
                                Finane &amp; Accounting Software
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/invoice-billing-management"
                              >
                                Invoice &amp; Billing Management
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/employee-payroll-management"
                              >
                                Employee payroll management [Including Pph21 +
                                BPJS Report]
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    menuOpen: false,
                                  })
                                }
                                to="/cloud-document-control"
                              >
                                Cloud Document Control &amp; Storage
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div
                          className="col-12 d-flex justify-content-center p-3"
                          style={{ backgroundColor: "#00317E" }}
                        >
                          <h6 className="sans text-uppercase text-yellow">
                            "We Provice <strong>Custom Request</strong> or{" "}
                            <strong>call us</strong>"
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container">
                    <div className="dropdown">
                      <Link
                        onClick={() =>
                          this.setState({ ...this.state, menuOpen: false })
                        }
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="nav-link"
                      >
                        Product
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link className="dropdown-item" to="/product-rfid">
                          RFID
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/product-smart-sensor"
                        >
                          Smart Sensor
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/product-qr-code-barcode"
                        >
                          QR Code & Barcode
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/product-gps-tracking-device"
                        >
                          GPS Tracking Device
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container">
                    <div className="dropdown">
                      <Link
                        onClick={() =>
                          this.setState({ ...this.state, menuOpen: false })
                        }
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="nav-link"
                      >
                        Services
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link
                          className="dropdown-item"
                          to="/services-custom-made-to-order-enterprise-software-solutions"
                        >
                          Custom Made-To-Order Enterprise Software Solutions
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/services-custom-made-to-order-iot-service-enablement"
                        >
                          Custom Made-To-Order IoT Service Enablement
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/services-data-analytics-dan-machine-learning"
                        >
                          Data Analytics and Machine Learning
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container">
                    <div className="dropdown">
                      <Link
                        onClick={() =>
                          this.setState({ ...this.state, menuOpen: false })
                        }
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="nav-link"
                      >
                        Industry
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link
                          className="dropdown-item"
                          to="/industry-automotive-manufacturing-software"
                        >
                          Automotive
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-construction-cement-or-concrete-precasting-batching-plant-manufacturing-software"
                        >
                          Construction/Cement
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-industry-electronic-manufacturing-software"
                        >
                          Electronic
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-metal-fabrication-manufacturing-software"
                        >
                          Metal Fabrication
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-plastics-and-or-rubber-manufacturing-software"
                        >
                          Plastics & Rubber
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-general-manufacturing-software"
                        >
                          General Manufacturing
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-wholesale-distribution-company-software"
                        >
                          Wholesale Distribution
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-general-manufacturing-software"
                        >
                          Transportation & Logistic
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-financial-services-organization-software"
                        >
                          Financial Services
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-energy-oil-gas-organization-software"
                        >
                          Energy, Oil & Gas
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/industry-education-organization-software"
                        >
                          Education
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="container">
                    <div className="dropdown">
                      <Link
                        onClick={() =>
                          this.setState({ ...this.state, menuOpen: false })
                        }
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="nav-link"
                      >
                        About Us
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link className="dropdown-item" to="/about-us">
                          Tentang Kami
                        </Link>
                        <Link className="dropdown-item" to="#">
                          <div className="d-flex">
                            <div className="col-10 p-0">Career</div>
                            <div className="col d-flex align-items-center justify-content-end p-0">
                              <span className="badge badge-pale">soon</span>
                            </div>
                          </div>
                        </Link>
                        <Link className="dropdown-item" to="#">
                          <div className="d-flex">
                            <div className="col-10 p-0">Hubungi Kami</div>
                            <div className="col d-flex align-items-center justify-content-end p-0">
                              <span className="badge badge-pale">soon</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() =>
                      this.setState({ ...this.state, menuOpen: false })
                    }
                    className="nav-link"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav navbar-kelolabiz-right my-2 my-lg-0">
                <li className="nav-item mr-4 button-green">
                  <a
                    className="nav-link"
                    // href="https://wa.me/6288908840808"
                    href="https://api.whatsapp.com/send?phone=6288908840808&amp;text=Halo"
                    target="_blank"
                  >
                    {" "}
                    <i className="mdi mdi-phone mr-2"></i>WhatsApp Only +62 889-0884-0808
                  </a>
                </li>
                <li className="nav-item mr-2 button-navy">
                  <Link
                    onClick={() =>
                      this.setState({ ...this.state, menuOpen: false })
                    }
                    to="/register"
                    className="nav-link"
                  >
                    Register
                  </Link>
                </li>
                <li className="nav-item button-navy">
                  <Link
                    onClick={() =>
                      this.setState({ ...this.state, menuOpen: false })
                    }
                    to="/login"
                    className="nav-link"
                  >
                    Login / Sign in
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </Fragment>
    );
  }
}

export default Navbar;
