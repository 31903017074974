import React, { Component, Fragment } from 'react'
import Slider from 'react-slick'

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 10000
}

export class Banner extends Component {
    render() {
        return (
            <Fragment>
                <Slider {...settings}>
                        <div className="col p-0 mt-5">
                            <div className="text-white d-flex" style={styles.banner}>
                                <div className="row d-flex justify-content-end px-5">
                                    <div className="col-md-6 col-sm-12 d-flex banner-text-wrapper">
                                        <div className="align-self-start">
                                            <h1 className="banner-title">Custom Software Solutions (Made-To-Order)</h1>
                                            <h5 className="banner-subtitle">Lebih Mengerti Kebutuhan Perusahaan Anda</h5>
                                            <p>Karena kami dapat Membuat, Menyesuaikan, dan Memodifikasi ERP / MRP
                                                Software spesifik Hanya Untuk Anda</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 mt-4" style={{ zIndex: 99 }}>
                                        <img src={require('../assets/img/banner01.svg')} alt="banner01" style={styles.bannerImg} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="col p-0 mt-5">
                            <div className="text-white d-flex" style={styles.banner}>
                                <div className="row d-flex justify-content-end px-5">
                                    <div className="col-md-6 col-sm-12 d-flex banner-text-wrapper">
                                        <div className="align-self-start">
                                            <h1 className="banner-title">SaaS (Software as a Service) – ERP / MRP</h1>
                                            <h5 className="banner-subtitle">Investasi Murah Bayar Bulanan</h5>
                                            <p>Kami menghilangkan kekhawatiran CAPEX yang Besar menjadi OPEX Bulanan yang
                                            Murah! Membuat Pekerjaan menjadi lebih Praktis dan Menyenangkan</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12" style={{ zIndex: 99 }}>
                                        <img src={require('../assets/img/banner03.svg')} alt="banner02" style={styles.bannerImg} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="col p-0 mt-5">
                            <div className="text-white d-flex" style={styles.banner}>
                                <div className="row d-flex justify-content-end px-5">
                                    <div className="col-md-6 col-sm-12 d-flex banner-text-wrapper">
                                        <div className="align-self-start">
                                            <h1 className="banner-title">IoT Device/Hardware (Smart Sensor)</h1>
                                            <h5 className="banner-subtitle">Membantu Memonitor &amp; Mengukur Kesuksesan Operasional</h5>
                                            <p>Merampingkan dan Menghilangkan proses manual, Pelacakan dan Verifikasi secara real-time
                                                kinerja kondisi Operasional Pabrik / Manufacture / Perusahaan lainnya</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12" style={{ zIndex: 99 }}>
                                        <img src={require('../assets/img/banner02.svg')} alt="banner03" style={styles.bannerImg} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </Slider>
            </Fragment>
        )
    }
}

const styles = {
    banner: {
        padding: '0px'
    },
    bannerImg: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}

export default Banner
