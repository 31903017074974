import React, { Component, Fragment } from 'react'
import GoogleMapReact from 'google-map-react'


const Marker = ({ mark, handleClick, address, name }) => {
    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {
                mark.name === name && mark.active && (
                    <div style={{ backgroundColor: '#fff', border: 'solid 1px #e1e1e1', borderRadius: '10px', width: '350px', padding: '10px', marginLeft: '-150px', position: 'absolute', top: '-100px' }}>
                
            
                <h6 className="sans font-weight-bold">PT. Kelola Bisnis Indonesia</h6>
                <p className="sans">
                    { address }
                </p>
            </div>)
            }

        <div>
            <i className="mdi text-danger mdi-map-marker mdi-48px img-cursor" onClick={handleClick}></i>
        </div>
    </div>)
}


export class Map extends Component {

    state = {
        mark: {
            name: 'bandung',
            active: false
        }
    }

    handleClick = (name) => {
        this.setState({
            ...this.state,
            mark: {
                name,
                active: !this.state.mark.active
            }
        })
    }

    render() {

        const locations = [{
            name: 'bandung',
            lat: -6.9456453,
            lng: 107.631471,
            address: 'Jl. Guntursari Wetan No.17, Turangga, Kec. Lengkong, Kota Bandung, Jawa Barat 40286'
        },
        {
            name: 'jakarta',
            lat: -6.227588,
            lng: 106.886349,
            address: 'Jl. Cipinang Muara Raya No.42 A Cipinang Muara Jatinegara - DKI Jakarta 13420'
        },
        {
            name: 'karawang',
            lat: -6.3328848,
            lng:107.3019623,
            address: 'Sirnabaya, East Telukjambe, Karawang Regency, West Java 41361'
        }]

        return (
            <Fragment>
                <div className="d-flex">
                    <div className="col p-0">
                        <div style={{ height: '80vh' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyA2NwJcVGR12eZ4Y5GP8cG9V_O4bO7ViP8' }}
                                defaultZoom={ 9 }
                                center={{ 
                                    lat: -6.580250,
                                    lng: 107.451523 }}
                                >
                                    {
                                        locations && locations.map(location => {
                                            return <Marker key={location.name} lat={location.lat} name={location.name}
                                            lng={location.lng} mark={this.state.mark} handleClick={() => this.handleClick(location.name)}
                                            address={location.address} />
                                        })
                                    }
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" style={{backgroundColor: '#00317D'}}>
                    <div className="d-flex p-4" >
                        <div className="col-md-2 text-right pr-5 border-right d-none d-md-block">
                            <h6 className="sans text-yellow font-weight-bold">Head Office</h6>
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 mb-4">
                                    <h6 className="sans text-yellow font-weight-bold img-cursor">Bandung</h6>
                                    <p className="text-white sans">
                                        Jl. Guntursari Wetan No.17, Turangga, Kec. Lengkong, Kota Bandung, Jawa Barat 40286
                                    </p>

                                </div>
                                <div className="col-sm-12 col-md-4 mb-4">
                                    <h6 className="sans text-yellow font-weight-bold img-cursor">Jakarta</h6>
                                    <p className="text-white sans">
                                        Jl. Cipinang Muara Raya No.42 A Cipinang Muara Jatinegara - DKI Jakarta 13420
                                    </p>

                                </div>
                                <div className="col-sm-12 col-md-4 mb-4">
                                    <h6 className="sans text-yellow font-weight-bold img-cursor">Karawang</h6>
                                    <p className="text-white sans">
                                        Sirnabaya, East Telukjambe, Karawang Regency, West Java 41361
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        )
    }
}

export default Map
