import React, { Component, Fragment } from 'react'
import Layout from '../components/Layout'
import { fetchPost, fetchRelated, fetchImage } from '../store/actions/postActions'
import { connect } from 'react-redux'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

export class Post extends Component {
    componentDidUpdate = (prevProps) => {
        if (prevProps.post !== this.props.post) {
            if (this.props.post.length > 0) {
                this.props.fetchRelated(this.props.post[0].categories)
                if (this.props.post[0].featured_media !== 0) {
                    this.props.fetchImage(this.props.post[0].featured_media)
                }
            }
        }

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.fetchPost(this.props.match.params.id)
        }


    }

    flatten = (html) => {
        const StrippedString = html.replace(/(<([^>]+)>)/ig,"")
        return StrippedString
    }

    componentDidMount = () => {
        this.props.fetchPost(this.props.match.params.id)
    }
    render() {
        const { 
            fetching, 
            fetched, 
            post,
            fetchedRelated,
            fetchingRelated,
            relateds,
            image
        } = this.props

        return (
            <Layout banner={false} featureImage={true} image={image && image.source_url}>
                
                <MetaTags>
                    <title>Kelolabiz | { post.length > 0 && this.flatten(post[0].title.rendered) }</title>
                    <meta name="description" content={ post.length > 0 && this.flatten(post[0].excerpt.rendered) } />
                    <meta property="og:title" content={ post.length > 0 && this.flatten(post[0].title.rendered) } />
                    <meta property="og:image" content={ image && image.source_url } />
                </MetaTags>

                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-3 sidebar px-0">
                            <h6 className="text-uppercase text-muted sans">Related Link</h6>
                            {
                                fetchingRelated ? (
                                    <small className="text-muted">Loading...</small>
                                ) : 
                                    fetchedRelated && relateds && post.length > 0 &&
                                        <ul className="sidebar-menu sans pt-4">
                                            {
                                                relateds.map(related => {
                                                return <li key={related.ID} className={this.props.match.params.id === related.post_name ? 'active' : '' }><Link to={`/${related.post_name}`}>{related.post_title}</Link></li>
                                                })
                                            }
                                        </ul>
                                    
                                
                            }
                        </div>
                        <div className="col-md-9">
                            {
                                fetching ? (
                                    <p>Loading ...</p>
                                ) : 
                                    fetched && post.length > 0 ? (
                                            
                                            <Fragment>
                                                <div className="post-title sans pl-5">{ parse(post[0].title.rendered) }</div>
                                                <div className="sans mt-3 pl-5">
                                                    {
                                                        parse(post[0].content.rendered)
                                                    }
                                                </div>
                                        </Fragment>

                                    ) : (
                                        
                                        <h6 className="sans text-muted">Not found</h6>

                                    )
                                        
                                
                            }
                        </div>
                            
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        post: state.post.post,
        fetching: state.post.fetching,
        fetched: state.post.fetched,
        errors: state.post.errors,
        relateds: state.related.relateds,
        fetchingRelated: state.related.fetching,
        fetchedRelated: state.related.fetched,
        image: state.image.image
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPost: (slug) => dispatch(fetchPost(slug)),
        fetchRelated: (category) => dispatch(fetchRelated(category)),
        fetchImage: (id) => dispatch(fetchImage(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Post)
